<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Professores </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarProfessor" class="mr-2">Criar Professor</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="professoresList"
          :loading="carregandoProfessores"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="professor.estaDeletado ? ['deleted'] : []"
                v-for="professor of items"
                :key="professor.id"
              >
                <td>{{ professor.id }}</td>
                <td>{{ professor.codigo }}</td>
                <td>{{ professor.nome }}</td>
                <td>{{ professor.email }}</td>
                <td><e-user-role :value="professor.user" /></td>
                <td>
                  <v-tooltip v-if="!professor.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => quadroHorario(professor)"
                      >
                        <v-icon small color="yellow">fa fa-star</v-icon>
                      </v-btn>
                    </template>
                    <span>Quadro de Horários</span>
                  </v-tooltip>

                  <v-tooltip v-if="!professor.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarProfessor(professor)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar professor</span>
                  </v-tooltip>
                  <v-tooltip v-if="!professor.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarProfessor(professor)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar professor</span>
                  </v-tooltip>
                  <v-tooltip v-if="professor.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarProfessor(professor)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar professor</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="page"
          @changeActionResponse="listarProfessores"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Professor from "../../Models/Professor";

export default {
  computed: {},
  data() {
    return {
      carregandoProfessores: false,
      professoresList: [],
      page: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Identificação Única(INEP)", value: "codigo" },
          { text: "Nome", value: "nome" },
          { text: "Email", value: "email" },
          { text: "Perfil", value: "role_id" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.listarProfessores();
  },
  watch: {
    async search(val) {
      if (val.length > 3) {
        await this.listarProfessores(0, val);
      }
      if (!val) {
        await this.listarProfessores(0);
      }
    },
    carregandoProfessores(value) {
      if (value && this.professoresList.length === 0) {
        this.$loaderService.open("Carregando dados");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    async listarProfessores(pageNumber, query) {
      this.carregandoProfessores = true;
      this.$loaderService.open("Carregando Professores");

      const response = await this.$services.professoresService.syncAllPaginate(pageNumber, query);

      this.professoresList = response.data.data.map((professor) => new Professor(professor));
      this.paginate = response.data;
      this.carregandoProfessores = false;
      this.$loaderService.close();
    },
    criarProfessor() {
      this.$router.push({ name: "professores.create" });
    },
    editarProfessor(professor) {
      this.$router.push({
        name: "professores.edit",
        params: { professor_id: professor.id },
      });
      // return professor;
    },
    quadroHorario(professor) {
      this.$router.push({
        name: "professores.quadroHorario",
        params: { professor_id: professor.id },
      });
      // return professor;
    },
    async deletarProfessor(professor) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta professor?",
        confirmationCode: professor.id,
        confirmationMessage: `Por favor, digite <strong>${professor.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o(a) Professor(a)");
              try {
                await this.$services.professoresService.deletarProfessor(professor);
                this.$toast.success("Professor(a) deletado(a) com sucesso");
                this.$forceUpdate();
                this.listarProfessores();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarProfessor(professor) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta professor?",
        confirmationCode: professor.id,
        confirmationMessage: `Por favor, digite <strong>${professor.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Professor");
              try {
                await this.$services.professoresService.recuperarProfessor(professor);
                this.$toast.success("Professor recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
