import ProfessoresIndexPage from "@/Pages/Professores/index.vue";
import NewEditProfessorPage from "@/Pages/Professores/NewEditProfessorPage.vue";
import QuadroHorarioProfessorPage from "@/Pages/Professores/QuadroHorarioProfessorPage.vue";

export default [
  {
    path: "/professores/",
    name: "professores",
    component: ProfessoresIndexPage,
  },
  {
    path: "/professores/create",
    name: "professores.create",
    component: NewEditProfessorPage,
    props: { editing: false },
  },
  {
    path: "/professores/edit/:professor_id",
    name: "professores.edit",
    component: NewEditProfessorPage,
    props: { editing: true },
  },
  {
    path: "/professores/quadro-horario/:professor_id",
    name: "professores.quadroHorario",
    component: QuadroHorarioProfessorPage,
    props: { editing: true },
  },
];
